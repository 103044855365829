<template>
  <div class="auth-container modal-container" @click="opened = false"
       :class="{'opened': opened}"
  >
    <div class="auth-wrapper modal-wrapper" @click.stop>
      <svg-icon
          class="close-modal"
          type="mdi"
          :path="mdiClose"
          size="22"
          @click="opened = false"
      />
      <div class="title">{{ item.id ? 'Редактирование' : 'Создание' }}</div>
      <div style="max-height: calc(100vh - 200px);overflow-y: scroll">
        <div class="fields">
          Основная картинка
          <ImageInput style="width: 100px;height: 100px" v-model="item.image"/>
          Доп картинки
          <ImagesInput v-model="item.images"/>
          Заголовок
          <input type="text"
                 class="email-field"
                 v-model="item.title"
          >
          Описание
          <textarea rows="4" v-model="item.description"/>
          <div style="display: flex;gap: 20px">
            <div>
              <div>Цена в рублях</div>
              <input type="text"
                     class="email-field"
                     v-model="item.price"
              >
            </div>
            <div>
              <div>Опубликован</div>
              <input style="width: 30px;" type="checkbox"
                     class="email-field"
                     v-model="item.published"
              >
            </div>
            <div>
              <div>Длина (см)</div>
              <input type="text"
                     class="email-field"
                     v-model="item.length"
              >
            </div>
            <div>
              <div>Ширина (см)</div>
              <input type="text"
                     class="email-field"
                     v-model="item.width"
              >
            </div>
            <div>
              <div>Высота (см)</div>
              <input type="text"
                     class="email-field"
                     v-model="item.height"
              >
            </div>
            <div>
              <div>Масса (г)</div>
              <input type="text"
                     class="email-field"
                     v-model="item.weight"
              >
            </div>
          </div>
          <div>Схожие товары</div>
          <div style="display: flex;gap: 20px">
            <div v-for="(items, key) in attachments" :key="key" style="flex: 1">
              <div style="cursor: pointer;user-select: none" v-for="attachment in items"
                   :key="attachment.id"
                   @click="toggleOption(key, attachment.id)">
                {{ isSelected(key, attachment.id) ? '✅' : '' }}{{ attachment.title }}
              </div>
            </div>
          </div>
        </div>
        <div style="color: red" v-if="error">{{ error }}</div>
        <div class="auth-actions">
          <Button :text="item.id ? 'Сохранить' : 'Создать'"
                  @click="update"
                  color="var(--primary-text-color)"
                  text_color="#FFFFFF"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mdiClose} from '@mdi/js';
import ImageInput from "@/components/ImageInput.vue";
import ImagesInput from "@/components/ImagesInput.vue";

export default {
  name: "ProductModal",
  components: {ImagesInput, ImageInput},
  data() {
    return {
      item: {id: 0},
      attachments: {courses: [], games: [], products: []},
      opened: false,
      error: '',
      mdiClose
    }
  },
  methods: {
    isSelected(key, id) {
      return this.item['attached_' + key] && this.item['attached_' + key].includes(id);
    },
    toggleOption(key, id) {
      key = 'attached_' + key;
      if (this.item[key] && this.item[key].includes(id)) this.item[key] = this.item[key].filter(item => item !== id);
      else if (!this.item[key]) this.item[key] = [id];
      else this.item[key] = [...this.item[key], id];
      this.item = {...this.item};
    },
    get() {
      this.error = '';
      this.$server.request('product/get/' + this.item.id, {admin: 1}, (data) => {
        if (data.response) this.item = data.response;
        for (let i in this.attachments) this.attachments[i] = data[i];
        this.item.published = !!(+this.item.published);
      }, (data) => {
        this.error = data.error || 'Нет соединения с интернетом';
      })
    },
    update() {
      this.error = '';
      let params = {...this.item};
      params.published = params.published ? 1 : 0;
      params.images = params.images.join(',');
      for (let i in this.attachments) if (params['attached_' + i]) params['attached_' + i] = params['attached_' + i].join(',');
      delete params.deleted;
      this.$server.request('product/' + (+this.item.id ? 'update/' + this.item.id : 'create'), params, (data) => {
        this.opened = false;
        this.$emit('update');
      }, (data) => {
        this.error = data.error || 'Нет соединения с интернетом';
      })
    },
    open(id) {
      this.item = {id, images: []};
      this.get();
      this.opened = true;
    }
  },
}
</script>

<style scoped lang="scss">
.auth-container {
  .auth-wrapper {
    .title {
      font-family: Arial;
      font-size: 24px;
    }

    .fields {
      display: flex;
      flex-direction: column;
      //margin-bottom: 16px;

      .change-label {
        margin-bottom: 4px;
        text-decoration: underline;
        cursor: pointer;
      }

      .email-field {
        margin-bottom: 16px;
      }
    }

    .auth-actions {
      display: flex;
      margin-top: 16px;
      justify-content: center;
    }
  }
}
</style>
