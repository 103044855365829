<template>
  <div class="item"
       ref="item"
       style="touch-action: none;"
       :style="styles"
       :class="{
        'small': size === 'small',
       'big': size === 'big',
       'flipped': isFlipped,
       'moving': movingClass,
       'draggable': isDraggable,
       'hidden': isHidden,
  }"
  >
    <div ref="rotate_btn" class="top-left-icons" v-if="type === 'card' && isDraggable" style="touch-action: none;">
      <svg-icon
          size="12"
          type="mdi" :path="mdiReload"></svg-icon>
    </div>
    <div class="top-right-icons" v-if="removeFromFieldIcon" @click="removeFromField(item)">
      <svg-icon
          size="12"
          type="mdi" :path="mdiClose"></svg-icon>
    </div>
    <div class="front"
         @mousedown="dragMouseDown"
         @touchstart="dragMouseDown"
    >
      <img @contextmenu="$event.preventDefault()" style="display: block;" @load="setSize" :src="front" alt="">
    </div>
    <div class="back">
      <img :src="back" alt="">
    </div>
    <span
        v-if="type === 'card' && isDraggable"
        @mousedown="startResize"
        @touchstart="startResize"
        class="item-number" ref="resizer_btn">{{ item.split('_')[1] }}</span>
  </div>
</template>

<script>
import {mdiReload, mdiClose} from '@mdi/js'

export default {
  name: "Item",
  props: {
    size: String,
    isDraggable: {
      default: false,
      type: Boolean
    },
    isHidden: {
      default: false,
      type: Boolean
    },
    item: {
      type: String
    },
    type: {
      type: String
    },
    initialWidth: {
      type: Number,
      default: 0,
    },
    removeFromFieldIcon: {
      type: Boolean,
      default: false
    },
    isFlipped: {
      type: Boolean,
      default: false
    },
    movingClass: {
      type: Boolean,
      default: false
    },
    initialPosition: {},
    back: {
      type: String,
    },
    value: {
      type: Object,
    },
    front: {
      type: String,
    }
  },
  data() {
    return {
      mdiReload,
      mdiClose,
      width: 0,
      height: 0,
      position: this.value || {
        x: 0,
        y: 0,
        z: 0,
        rotate: 0,
        height: 0,
        width: 0,
      },
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      },
      rotating: false,
      moving: false,
      resizeParams: {
        width: 0,
        height: 0,
        clientX: undefined,
        clientY: undefined,
        scale: 1
      }
    }
  },
  computed: {
    styles() {
      let styles = {};
      if (this.position.z) styles['z-index'] = this.position.z;
      if (this.position.width) styles.width = this.position.width + 'px';
      if (this.position.height) styles.height = this.position.height + 'px';
      if (this.position.x) styles.left = this.position.x + 'px';
      if (this.position.y) styles.top = this.position.y + 'px';
      if (this.position.rotate) styles.transform = `rotate(${this.position.rotate}deg)`;
      if (this.initialWidth && !this.position.width) styles.width = `${this.initialWidth}px`;
      return styles;
    }
  },
  watch: {
    value() {
      this.position = this.value;
    },
  },
  methods: {
    dragMouseDown(event) {
      if (this.moving) return;
      this.moving = true;
      if (this.isDraggable) {
        window.maxZIndex++;
        this.updatePosition({z: window.maxZIndex});
        event.preventDefault()
        let coords = event.clientX ? event : event.touches[0];
        this.positions.clientX = coords.clientX;
        this.positions.clientY = coords.clientY;
        document.onmousemove = this.elementDrag
        document.ontouchmove = this.elementDrag
        document.onmouseup = this.closeDragElement
        document.ontouchend = this.closeDragElement
      }
    },
    setSize() {
      if (!this.$refs.item) return console.warn('this.$refs.item undefined 1');
      setTimeout(() => {

        let width = this.$refs.item.offsetWidth;
        let height = this.$refs.item.offsetHeight;
        if (!width || !height) return;
        this.height = height
        this.width = width
        this.updatePosition({height, width})

      }, 300)
    },
    updatePosition(params) {
      for (let i in params) this.position[i] = params[i];
      this.$emit('change', this.position);
    },
    elementDrag(event) {
      if (this.rotating) return;
      event.preventDefault()
      let coords = event.clientX ? event : event.touches[0];
      this.positions.movementX = this.positions.clientX - coords.clientX
      this.positions.movementY = this.positions.clientY - coords.clientY
      this.positions.clientX = coords.clientX
      this.positions.clientY = coords.clientY
      if (!this.$refs.item) return console.warn('this.$refs.item undefined 2');
      this.updatePosition({
        x: this.$refs.item.offsetLeft - this.positions.movementX,
        y: this.$refs.item.offsetTop - this.positions.movementY
      });
    },
    closeDragElement() {
      this.moving = false;
      document.ontouchmove = null
      document.ontouchend = null
      document.onmouseup = null
      document.onmousemove = null
    },
    removeFromField(card) {
      this.$eventBus.emit('remove-card-from-field', card)
    },
    rotate() {
      if (this.type === 'card' && this.isDraggable) {
        if (!this.$refs.item) return console.warn('this.$refs.item undefined 3');
        let card = this.$refs.item;
        let rotate_btn = this.$refs.rotate_btn;
        (() => {
          let R2D, angle, center, init, rotate, rotation, start, startAngle, stop;
          angle = 0;
          rotation = 0;
          startAngle = 0;
          center = {
            x: 0,
            y: 0
          };
          init = function () {
            rotate_btn.addEventListener("mousedown", start, false);
            rotate_btn.addEventListener("touchstart", start, false);
          };
          R2D = 180 / Math.PI;
          start = (e) => {
            document.addEventListener("mousemove", rotate, false);
            document.addEventListener("touchmove", rotate, false);
            document.addEventListener("mouseup", stop, false);
            document.addEventListener("touchend", stop, false);
            let coords = e.clientX ? e : e.touches[0];
            this.rotating = true;
            e.stopPropagation();
            let height, left, top, width, x, y, _ref;
            e.preventDefault();
            _ref = card.getBoundingClientRect(), top = _ref.top, left = _ref.left, height = _ref.height, width = _ref.width;
            center = {
              x: left + (width / 2),
              y: top + (height / 2)
            };
            x = coords.clientX - center.x;
            y = coords.clientY - center.y;
            startAngle = R2D * Math.atan2(y, x);
            return true;
          };
          rotate = (e) => {
            e.stopPropagation();
            let coords = e.clientX ? e : e.touches[0];
            if (this.rotating) {
              let d, x, y;
              // e.preventDefault();
              x = coords.clientX - center.x;
              y = coords.clientY - center.y;
              d = R2D * Math.atan2(y, x);
              rotation = d - startAngle;
              this.updatePosition({rotate: angle + rotation})
              // return card.style.transform = "rotate(" + (angle + rotation) + "deg)";
            }
          };
          stop = () => {
            if (this.rotating) {
              angle += rotation;
              document.removeEventListener("mousemove", rotate, false);
              document.removeEventListener("touchmove", rotate, false);
              document.removeEventListener("mouseup", stop, false);
              document.removeEventListener("touchend", stop, false);
              return this.rotating = false;
            }
          };
          init();
        }).call(this);
      }
    },
    startResize(e) {
      e.preventDefault()
      document.onmousemove = this.resize
      document.ontouchmove = this.resize
      document.onmouseup = this.endResize
      document.ontouchend = this.endResize

    },
    resize(event) {
      if (!this.$refs.item) return console.warn('this.$refs.item undefined 4');
      let element = this.$refs.item;
      let coords = event.clientX ? event : event.touches[0];
      this.resizeParams.clientX = coords.clientX
      this.resizeParams.clientY = coords.clientY
      let newWidth = this.resizeParams.clientX - element.offsetLeft;
      if (newWidth < 100) newWidth = 100;
      this.updatePosition({width: newWidth, height: Math.floor(newWidth / this.width * this.height)});
    },
    endResize() {
      document.onmouseup = null
      document.ontouchmove = null
      document.ontouchend = null
      document.onmousemove = null
    },
  },
  mounted() {
    if (this.initialPosition) {
      // this.updatePosition({x: this.initialPosition[0], y: this.initialPosition[1]});
    }
    this.rotate()
  }
}
</script>

<style scoped lang="scss">
.item {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
  top: 0;
  left: 0;

  &.moving {
    box-shadow: 0 0 5px black;
    //opacity: .5;
    pointer-events: none;
    user-select: none;
  }

  &.small {
    flex-basis: calc(50% - 5px);
  }

  &.big {
    height: auto;
    width: 270px;
  }

  &:last-child {
    margin-right: 0;
  }

  &.draggable {
    position: absolute;
    z-index: 10;
    cursor: move;

    .front, .back {
      position: static;
    }
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.flipped {
    .front {
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }

    .back {
      -webkit-transform: rotateY(180deg);
      -moz-transform: rotateY(180deg);
      -o-transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      transform: rotateY(180deg);
      display: none;
    }
  }

  .front, .back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 0.6s;
    -moz-transform-style: preserve-3d;
    -o-transition: 0.6s;
    -o-transform-style: preserve-3d;
    -ms-transition: 0.6s;
    -ms-transform-style: preserve-3d;
    transition: 0.6s;
    transform-style: preserve-3d;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .front {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    position: absolute;
  }

  .back {

  }

  .top-right-icons {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 2px 0;
    z-index: 11;
    cursor: pointer;
    background: antiquewhite;
    border-radius: 0 0 0 10px;
    -webkit-transform:translateZ(0px);
  }

  .top-left-icons {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 2px 0;
    z-index: 11;
    cursor: nesw-resize;
    background: antiquewhite;
    border-radius: 0 0 10px 0;
    -webkit-transform:translateZ(0px);
  }

  .item-number {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: -2px;
    right: -2px;
    border-radius: 50%;
    font-size: 12px;
    line-height: 22px;
    background: antiquewhite;
    text-align: center;
    cursor: nwse-resize;
    z-index: 111;
    -webkit-transform:translateZ(0px);
  }
}
</style>
