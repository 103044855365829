<template>
  <page-container style="max-width: 900px">
    <div class="title">Заказ успешно оформлен</div>
    <div style="text-align: center;font-size: 24px">
      Спасибо за заказ, мы скоро с вами свяжемся!
    </div>
  </page-container>
</template>

<script>
import PageContainer from "@/components/PageContainer";
import Contacts from "@/components/Contacts";

export default {
  name: "OrderSuccess",
  components: {Contacts, PageContainer},
  mounted() {
    document.getElementById('app').classList.add('hide_bg');
  },
  beforeUnmount() {
    document.getElementById('app').classList.remove('hide_bg');
  }
}
</script>

<style scoped>

</style>
