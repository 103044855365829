import {createRouter, createWebHistory} from "vue-router";

import Home from "@/pages/Home";
import About from "@/pages/About";
import Education from "@/pages/Education";
import Decks from "@/pages/meta/Decks";
import Games from "@/pages/psycho/Games";
import Game from "@/pages/psycho/Game";
import GameInfo from "@/pages/psycho/Info";
import Cubes from "@/pages/Cubes";
import Shop from "@/pages/Shop";
import Contacts from "@/pages/Contacts";
import Offer from "@/pages/Offer";
import Agreement from "@/pages/Agreement";
import Agreement2 from "@/pages/Agreement2";
import Admin from "@/pages/Admin";
import Program from "@/pages/psycho/Program";
import Cart from "@/pages/Cart";
import Instructions from "@/pages/Instructions";
import CourseEdit from "@/pages/courses/CourseEdit";
import Courses from "@/pages/courses/Courses";
import EduDocuments from "@/pages/EduDocuments2";
import CourseInfo from "@/pages/courses/CourseInfo";
import Product from "@/pages/psycho/Product.vue";
import Sdek from "@/pages/Sdek.vue";
import OrderSuccess from "@/pages/OrderSuccess.vue";
const routes = [
    {
        path: '/',
        component: Home,
    },
    {
        path: '/about',
        component: About
    },
    {
        path: '/education',
        component: Education
    },
    {
        path: '/offer',
        component: Offer
    },
    {
        path: '/agreement',
        component: Agreement
    },
    {
        path: '/agreement2',
        component: Agreement2
    },
    {
        path: '/order_success',
        component: OrderSuccess
    },
    {
        path: '/program/:id?',
        component: Program
    },
    {
        path: '/courses/:id/edit',
        component: CourseEdit
    },
    {
        path: '/courses/:id',
        component: CourseInfo
    },
    {
        path: '/edu_documents/',
        component: EduDocuments
    },
    {
        path: '/courses/new',
        component: CourseEdit
    },
    {
        path: '/courses',
        component: Courses
    },
    {
        path: '/meta_cards',
        component: Decks
    },
    {
        path: '/cart',
        component: Cart
    },
    {
        path: '/games/:type?/:online?/:tab?',
        component: Games,
    },
    {
        path: '/game/:token',
        component: Game
    },
    {
        path: '/games/:id/info',
        component: GameInfo
    },
    {
        path: '/products/:id',
        component: Product
    },
    {
        path: '/cubes',
        component: Cubes
    },
    {
        path: '/instructions',
        component: Instructions
    },
    {
        path: '/shop',
        component: Shop
    },
    {
        path: '/contacts',
        component: Contacts
    },
    {
        path: '/adminka/:tab?',
        component: Admin
    },
    {
        path: '/sdek',
        component: Sdek
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history'
})

export default router
