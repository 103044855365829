<template>
    <div id="cdek-map" style="width:100%;height:600px"></div>
</template>

<script>
export default {
  name: "Sdek",
  mounted() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.jsdelivr.net/npm/@cdek-it/widget@3';
    script.charset = 'utf-8';
    document.body.appendChild(script);
    let lat, lng;
    this.getLocation((lat1, lng1) => {
      console.log({lat1, lng1});
      lat = lat1;
      lng = lng1;
    })
    script.onload = () => {
      this.initWidget(lat, lng);
    }
  },
  methods: {
    getLocation(callback) {
      window.navigator.geolocation.getCurrentPosition(
          position => callback(position.coords.latitude,position.coords.longitude),
          callback
      )
    },
    initWidget(lat, lng) {
      const defaultLocation = (lat && lng) ? [lat, lng] : 'Москва'
      const that = this;
      window.sdek_widget = new window.CDEKWidget({
        from: {
          country_code: 'RU',
          city: 'Новосибирск',
          postal_code: 630009,
          code: 270,
          address: 'ул. Большевистская, д. 101',
        },
        root: 'cdek-map',
        apiKey: '8beab166-c1f6-4f4d-8e3b-80aaf3be9168',
        servicePath: this.$server.url + 'sdek/service',
        defaultLocation,
        canChoose: true,
        tariffs: {
          office: [234, 136],
          door: [233, 137],
        },
        // fixBounds: 'locality',
        // defaultLocation: [55.0415, 82.9346],
        goods: [
          {
            width: 10,
            height: 10,
            length: 10,
            weight: 1,
          },
        ],
        onReady(a,b,c) {
        },
        onCalculate(tariff, address) {
          console.log('Расчет стоимости доставки произведен', {tariff, address});
        },
        onChoose(type, tariff, address) {
          that.$emit('change', {type, tariff, address});
          console.log('Доставка выбрана', {type, tariff, address});
        },
      });
    }
  }
}
</script>

<style scoped>

</style>
